<template>
    <div class="question-answer">
        <b-card>
            <template #header>
                <div class="d-flex justify-content-start align-items-center">
                    <unicon
                        name="users-alt"
                        class="mr-1"
                        style="fill: #6e6b7b"
                    ></unicon>
                    <h4 class="m-0">معلومات مجيبو الاسئلة</h4>
                </div>
            </template>
            <validationObserver ref="questionForm">
                <b-row>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="الاسم والكنية"
                            label="الاسم والكنية"
                            placeholder="ادخل الاسم واالكنية"
                            v-model="dtoDetail.name"
                            :rules="[
                                { type: 'required', message: 'اسم مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="البريد الالكتروني"
                            label="البريد االالكتروني"
                            placeholder="ادخل  البريد الالكتروني"
                            v-model="dtoDetail.email"
                            :rules="[
                                { type: 'required', message: 'الاسم مطلوب' },
                                {
                                    type: 'email',
                                    message: 'يرجى ادخال بريد الكتروني صحيح',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-date-picker
                            :rules="[
                                { type: 'required', message: 'تاريخ الاشتراك' },
                            ]"
                            v-model="dtoDetail.dateCreated"
                            placeholder="ادخل  تاريخ الميلاد"
                            name="تاريخ الميلاد"
                            label="تاريخ الميلاد"
                        ></ek-date-picker>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="كلمة السر الجديدة"
                            label="كلمة السر الجديدة"
                            v-model="dtoDetail.newPassword"
                            :rules="[
                                {
                                    type: 'min:8',
                                    message:
                                        'كلمة المرور يجب ان تكون اطول من 8 محارف',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="رقم الهاتف"
                            label="رقم الهاتف"
                            placeholder="ادخل  رقم الهاتف"
                            v-model="dtoDetail.phoneNumber"
                            :rules="[
                                { type: 'required', message: 'رقم مطلوب' },
                                {
                                    type: 'mobile',
                                    message: 'رقم الهاتف غير صالح',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>
                </b-row>
            </validationObserver>
            <template #footer>
                <div class="w-100 d-flex justify-content-between">
                    <div>
                        <b-button
                            variant="main-color"
                            class="mr-1"
                            @click="submit()"
                            >تعديل</b-button
                        >
                        <b-button variant="outline-primary" @click="backBtn"
                            >تراجع</b-button
                        >
                    </div>

                    <div>
                        <b-button
                            @click="setIsBlock"
                            variant="outline-danger"
                            class="mr-1"
                            >{{
                                dtoDetail.isBlocked ? "الغاء الحظر" : "حظر"
                            }}</b-button
                        >
                        <b-button
                            @click="deletePoint(id)"
                            variant="outline-primary"
                            >حذف</b-button
                        >
                    </div>
                </div>
            </template>
        </b-card>
        <h2>الاجابات</h2>
        <ek-table
            :items="dtoDetail.answers"
            :columns="columnsAnswer"
            no_delete
            no_select
            @details="goToDetails"
        >
            <template slot="items.answerDate" slot-scope="{ value }">
                <span>{{ new Date(value).toLocaleDateString() }}</span>
            </template>
            <template slot="items.questionDate" slot-scope="{ value }">
                <span>{{ new Date(value).toLocaleDateString() }}</span>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    props: ["id"],
    data() {
        return {};
    },
    computed: {
        ...mapState({
            dtoDetail: (state) => state.questionsAnswerer.dtoDetail,
            columnsAnswer: (state) => state.questionsAnswerer.columnsAnswer,
        }),
    },

    methods: {
        submit() {
            this.$refs["questionForm"].validate().then((suc) => {
                if (suc) {
                    this.updateQuestionsAnswerer(this.dtoDetail);
                }
            });
        },
        backBtn() {
            this.$router.push(`/admin/questionsAnswerer`);
        },
        deletePoint(id) {
            this.deleteQuestionAnswerer(id);
        },
        setIsBlock() {
            if (!this.dtoDetail.isBlocked) {
                this.blockQuestionAnswerer(this.id);
            } else this.unblockQuestionAnswerer(this.id);
        },
        ...mapActions([
            "getByIdQuestionsAnswerer",
            "deleteQuestionAnswerer",
            "updateQuestionsAnswerer",
            "blockQuestionAnswerer",
            "unblockQuestionAnswerer",
        ]),
        goToDetails({ row }) {
            // this.$router.push("/admin/lessons");
            this.$router.push(
                `/admin/questionAnswerDashBoard/incommingQuestion/${row.id}`
            );
        },
    },
    created() {
        this.getByIdQuestionsAnswerer(this.id);
    },
};
</script>

<style lang="scss">
.card-footer {
    border: 0px !important;
}
</style>
